<template>
  <div class="church-profile template-3 manageromo-section">
    <header class="page-header container">
      <h1>{{ translations.tcManageEvents }}</h1>
    </header>
    <div id="card" class="container">
      <div class="list-area">
        <data-table
          :title="translations.tcConventionsConferencesBiltzes"
          :tooltip="tooltip"
          :fields="dataTableFields"
          :sortBy="sortBy"
          :items="conventions"
          :addItems="addItems"
          @addEvent="addEvent"
          @editEvent="editEvent"
          @deleteEvent="deleteEvent"
          @activeToggleEvent="activeToggleEvent"
          :i18n="translations.components"
        ></data-table>
        <!-- / Data Tables -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import DataTable from '@/components/DataTableConventions.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'manage-events',
  mixins: [translationMixin],
  data() {
    return {
      sortBy: 'event_name',
      translations: {},
      tooltip: 'Conventions/Conferences/Blitzes',
      dataTableFields: [],
      addItems: {},
      view_calendar_event_controls: {
        calendar_add_event_button: '20a78086-7644-4a38-9162-085f27489166',
      },
    }
  },
  async created() {
    if (this.iCanSee(this.view_calendar_event_controls.calendar_add_event_button)) {
      this.addItems = {
        display: true,
        text: 'Add Event',
        action: 'addEvent',
      }
    }
    await this.page_load()
  },
  methods: {
    ...mapActions({
      deleteConvention: 'stateConvention/deleteConvention',
      loadConventions: 'stateConvention/loadConventions',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedConventionKey: 'user/setSelectedConventionKey',
      toggleActiveConvention: 'stateConvention/toggleActiveConvention',
    }),
    async page_load() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getTranslations(),
        this.getComponentTranslations('data-table-conventions'),
      ]).then((results) => {
        const componentTranslations = results[2]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
        this.loadConventions()
        this.setLoadingStatus(false)
      })
    },
    async addEvent() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.setSelectedConventionKey(''),
        this.setLoadingStatus(false),
      ]).then(() => {
        this.$router.push({ name: 'add-convention' })
      })
    },
    async deleteEvent(data) {
      let response = null
      const con = {
        event_key: data,
        user_key: this.userId,
        user: null,
        delete_flag: true,
      }
      await Promise.all([
        this.setLoadingStatus(true),
        (response = await this.deleteConvention(con)),
        await this.loadConventions(),
        this.setLoadingStatus(false),
      ]).then(() => {
        if (!response) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorUpdatingConventionDelete,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            return
          })
        }
      })
    },
    async editEvent(data) {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.setSelectedConventionKey(data),
        this.setLoadingStatus(false),
      ]).then(() => {
        this.$router.push({ name: 'add-convention' })
      })
    },
    async activeToggleEvent(data) {
      let response = null
      const con = {
        event_key: data.item.event_key,
        user_key: this.userId,
        user: null,
        active_flag: data.value,
      }
      await Promise.all([
        this.setLoadingStatus(true),
        (response = await this.toggleActiveConvention(con)),
        await this.loadConventions(),
        this.setLoadingStatus(false),
      ]).then(() => {
        if (!response) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorUpdatingConventionActive,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            return
          })
        }
      })
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddEvent
      this.tooltip = this.translations.tcTooltip
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.dataTableFields = [
        { key: 'event_name', label: `${this.translations.tcEvent}`, sortable: true },
        { key: 'event_active_flag', label: `${this.translations.tcActive}`, sortable: true },
        { key: 'event_key', label: `${this.translations.tcEditDelete}`, sortable: false },
      ]
    },
  },
  computed: {
    ...mapGetters({
      conventions: 'stateConvention/conventions',
      iCanSee: 'user/iCanSee',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userSelectedConventionKey: 'user/userSelectedConventionKey',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
  },
  components: {
    dataTable: DataTable,
    iTooltip: iTooltip,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/views/ChurchProfile.scss';
@import '@/styles/views/ManagePromo.scss';

h1 {
  text-transform: uppercase;
}
</style>
